<template>
  <div class="vipShopList_father">
    <!-- <div class="headImg">
      <swipe :autoplay="3000" class="height100">
        <swipe-item v-for="(image, index) in images" :key="index">
          <img v-lazy="image" />
        </swipe-item>
      </swipe>
    </div> -->
    <div class="vipShopList">
      <div class="box" v-for="(item, index) in shopList" :key="index">
        <div class="box_top">
          <div class="t_left">
            <img :src="item.cover_url[0]" alt />
          </div>
          <div class="t_right">
            <div class="title">
              <div class="title_l">{{item.name}}</div>
              <div><Button round type="primary" size="mini" text="立即洗车" @click="$router.push('washCarDetail?storeId='+item.id)"></Button></div>
            </div>
            <div class="address">
              <div class="b_b_left">
                <img src="@static/vip/myaddress.png" alt style="width: 10px;" />
                {{item.area}} {{item.address}}
              </div>
              <div class="title_r" v-if="isWeixin_status && item.dis">
                <span>{{item.dis}}Km</span>
              </div>
            </div>
          </div>
        </div>
        <!-- 拨打电话 -->
        <!-- <div class="operation" v-if="page_status == 1">
          <a :href="`tel:${item.mobile}`" class="ringUp">
            <icon size="20" name="phone-o" />
            <span>拨打电话</span>
          </a>
          <div class="navigation" @click="goAddress(item)">
            <icon size="20" name="location-o" />
            <span>立即前往</span>
          </div>
        </div>
        <div v-else class="operation">
          <div class="navigation" @click.stop="goPayMoney(item)">
            <span>立即加油</span>
          </div>
        </div> -->
      </div>
      <div class="lookOverMore" @click="lookOverMore" v-if="show">查看更多~</div>
      <div class="lookOverMore noBack" v-else>没有更多了!</div>
    </div>
  </div>
</template>

<script>
import { Button } from "vant";
import wx from "weixin-js-sdk";
import { goWxConfigJsApi } from "@/utils/wxpay";
import { getDistance } from "@/utils/tools";
// import Config from "@/api/setting";
import washCar from "@/api/washCar";
export default {
  components: {
    Button,
  },
  data() {
    return {
      screenvalue: "",
      page: 1,
      shopList: [],
      show: true,
      page_status: 1,
      images: [
        require("@static/vip/ban_1.png"),
        require("@static/vip/ban_2.png")
      ],
      value1: "价格",
      value2: "a",
      value3: "a",
      latitude: "",
      longitude: ""
    };
  },
  created() {
    if (this.$route.query.page_status) {
      this.page_status = this.$route.query.page_status;
    }
    if (this.isWeixin_status) {
      // 微信配置
      goWxConfigJsApi("openLocation,getLocation");
    }
  },
  mounted() {
    this.$nextTick(() => {
      if (this.isWeixin_status) {
        this.$toast.loading({
          duration: 0, // 持续展示 toast
          forbidClick: true, // 禁用背景点击
          message: "加载中...",
          loadingType: "spinner",
          selector: "#custom-selector"
        });
        this.getShopList(1);
      } else {
        this.$toast.loading({
          duration: 0, // 持续展示 toast
          forbidClick: true, // 禁用背景点击
          message: "加载中...",
          loadingType: "spinner",
          selector: "#custom-selector"
        });
        this.getShopList(1);
      }
    }, 800);
  },
  methods: {
    goPayMoney(item) {
      this.$router.push(
        `/cardpay?type=0&store_id=${item.store_id}&room_id=${item.id}`
      );
    },
    // // 设置appid 信息
    // goDetail(item) {
    //   localStorage.setItem("app_id", item.app_id);
    //   if (this.isWeixin_status) {
    //     if (this.appid == item.app_id) {
    //       sessionStorage.setItem("now_store", JSON.stringify(item));
    //       this.$router.push(`/vip_mycenter?appid=${item.app_id}`);
    //     } else {
    //       localStorage.clear(), sessionStorage.clear();
    //       var redirectUrl = `https://jjtc.58zltc.com/vip_mycenter?appid=${item.app_id}`;
    //       let url = encodeURI(
    //         "https://www.58zltc.com/wechat/user.login/snsapi_base?app_id=" +
    //           item.app_id +
    //           "&callback=" +
    //           redirectUrl
    //       );
    //       window.location.href = url;
    //     }
    //   } else {
    //     if (this.appid == item.app_id) {
    //       sessionStorage.setItem("now_store", JSON.stringify(item));
    //       this.$router.push(`/vip_mycenter?appid=${item.app_id}`);
    //     } else {
    //       localStorage.clear(), sessionStorage.clear();
    //       this.$router.push(`/userLogin?appid=${item.app_id}`);
    //     }
    //   }
    // },
    // 加载更多
    lookOverMore() {
      this.$toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true, // 禁用背景点击
        message: "加载中...",
        loadingType: "spinner",
        selector: "#custom-selector"
      });
      this.getShopList(++this.page);
    },
    // 计算两地之间距离
    setDistance(shopArr) {
      var _this = this;
      wx.getLocation({
        type: "gcj02", // 默认为wgs84的gps坐标，如果要返回直接给openLocation用的火星坐标，可传入'gcj02'
        success: function(res) {
          _this.latitude = res.latitude; // 纬度，浮点数，范围为90 ~ -90
          _this.longitude = res.longitude; // 经度，浮点数，范围为180 ~ -180。
          var arr = [];
          console.log(shopArr);
          for (let i = 0; i < shopArr.length; i++) {
            // 计算距离
            shopArr[i].dis = getDistance(
              shopArr[i].lat,
              shopArr[i].lng,
              res.latitude,
              res.longitude
            );
            arr.push(shopArr[i]);
          }
          _this.shopList = _this.quickSort(arr);
          console.log("距离",_this.shopList);
          _this.$toast.clear();
        }
      });
    },
    // 调用微信地址
    goAddress(item) {
      if (this.isWeixin_status) {
        wx.openLocation({
          latitude: item.lat, // 纬度，浮点数，范围为90 ~ -90
          longitude: item.lng, // 经度，浮点数，范围为180 ~ -180。
          name: item.area, // 位置名
          address: "", // 地址详情说明
          scale: 16, // 地图缩放级别,整形值,范围从1~28。默认为最大
          infoUrl: "" // 在查看位置界面底部显示的超链接,可点击跳转
        });
      } else {
        this.$toast("请在微信环境中打开...");
      }
    },
    goBack() {
      this.$router.go(-1);
    },
    async getShopList(page) {
      try {
        const res = await washCar.getStoreList(page);
        if (res.code == 200) {
          var data = res.data.result;
          if (res.data.total_num > 0) {
            if (this.isWeixin_status) {
              for (let i in data) {
                this.shopList.push(data[i])
              }
              if (this.shopList.length == res.data.total_num) {
                this.show = false
              }
              this.setDistance(this.shopList);
            } else {
              for (let i in data) {
                this.shopList.push(data[i])
              }
              if (this.shopList.length == res.data.total_num) {
                this.show = false
              }
            }
            this.$toast.clear();
          } else {
            this.show = false;
            this.page = 1;
            this.$toast(res.msg);
            setTimeout(() => {
              this.$toast.clear();
            }, 800)
          }
        }
      } catch (error) {
        this.$toast.clear();
        console.log(error);
      }
    }
  }
};
</script>

<style lang="scss" scoped>

.headImg {
  height: 150px;
  margin-bottom: 5px;
  position: relative;
  .height100 {
    height: 100%;
  }
  img {
    width: 100%;
    height: 100%;
  }
  .screen {
    opacity: 0.6;
    position: absolute;
    width: 100%;
    left: 0;
    top: 30px;
  }
}
.small_module {
  // height: 70px;
  display: flex;
  flex-direction: row;
  margin: 5px 10px;
  padding: 10px 5px;
  background: #fff;
  .lft,
  .rgt {
    flex: 1;
    display: flex;
    flex-direction: row;
    position: relative;
    margin-left: 5px;
    .lft_img {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      overflow: hidden;
      margin-right: 5px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .lft_detail {
      .top {
        font-size: 16px;
        font-weight: bold;
        line-height: 24px;
      }
      .btm {
        font-size: 12px;
        color: #b9b9b9;
      }
    }
  }
  .lft:after {
    position: absolute;
    content: "";
    width: 1px;
    height: 40px;
    right: 3px;
    top: 50%;
    margin-top: -20px;
    background: rgb(192, 192, 192);
  }
}
.vipShopList {
  background: #f3f3f3;
  padding: 0px 10px 15px;
  padding-bottom: 50px;
  padding-top: 10px;
  .box {
    width: 100%;
    height: auto;
    margin-bottom: 10px;
    background: rgba(255, 255, 255, 1);
    border-radius: 5px;
    padding: 0px 6px;
    // padding-bottom: 10px;
    .box_top {
      height: auto;
      padding: 10px 2px;
      display: flex;
      .t_left {
        width: 40px;
        height: 40px;
        margin-right: 14px;
        overflow: hidden;
        display: flex;
        justify-content: center;
        img {
          height: 100%;
          width: 100%;
        }
      }
      .address {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 5px;
      }
      .t_right {
        flex: 1;
        .title {
          // height: 16px;
          display: flex;
          justify-content: space-between;
          .title_l {
            font-size: 18px;
            font-weight: bold;
            color: #333;
          }
          /deep/ .van-button{
            padding: 0 10px;
            
          }
        }
        .title_r {
          flex: 1;
          margin-left: 10px;
          padding: 3px 5px;
          background: #ccac39;
          color: #fff;
          text-align: center;
          border-radius: 5px;
        }

        .sign {
          width: 110px;
          height: 18px;
          font-size: 11px;
          display: flex;
          border-radius: 3px;
          padding: 0 2px;
          align-items: center;
          justify-content: space-between;
          background: linear-gradient(
            180deg,
            rgba(55, 150, 255, 1) 0%,
            rgba(113, 198, 255, 1) 100%
          );
          div:nth-child(1) {
            color: #fff;
            border-radius: 2px 0px 0px 2px;
          }
          div:nth-child(2) {
            background: #fff;
            padding: 0 4px;
            color: rgba(55, 150, 255, 1);
          }
        }
      }
      .b_b_left {
        // margin-top: 5px;
        width: 180px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
    .prices {
      display: flex;
      justify-content: space-between;
      // flex-wrap: wrap;
      color: #cc393b;
      // padding-top: 14px;
      .prices_lft {
        width: 80px;
        display: flex;
        align-items: center;
        justify-content: center;
        .width80 {
          width: 50px;
          text-align: center;
          height: 16px;
          line-height: 16px;
          justify-content: center;
        }
      }
      .prices_rgt {
        text-align: center;
        flex: 1;
        display: flex;
        flex-direction: row;
        width: 250px;
      }
      .p {
        flex: 1;
        width: 58px;
        margin: 0 3px;
        background: #cc393b14;
        padding: 3px 2px;
        border-radius: 4px;
      }
      .money {
        font-size: 14px;
        font-weight: 600px;
      }
    }
    .operation {
      margin-top: 8px;
      border-top: 1px solid #b1b1b145;
      border-bottom: 1px solid #b1b1b145;
      height: 40px;
      display: flex;
      flex-direction: row;
      text-align: center;
      line-height: 40px;
      font-size: 14px;
      span {
        margin-left: 10px;
      }
      .ringUp {
        color: #333;
        flex: 1;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .ringUp:after {
        position: absolute;
        content: "";
        width: 1px;
        height: 34px;
        right: 0;
        top: 50%;
        margin-top: -17px;
        background: #b1b1b145;
      }
      .navigation {
        flex: 1;
        display: flex;
        font-size: 16px;
        justify-content: center;
        align-items: center;
      }
    }
    .shopIfor {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding: 5px 14px 10px;
      .tel {
        height: 18px;
        line-height: 18px;
        // font-size: 14px;
        // margin-top: 15px;
      }
      .businessTime {
        height: 18px;
        line-height: 18px;
        // margin-top: 10px;
      }
    }

    .box_bottom {
      width: 100%;
      border-top: 1px solid #d8d8d8;
      height: auto;
      display: flex;
      padding: 10px 20px;
      align-items: center;
      justify-content: space-between;
      .b_b_right {
        .btn {
          width: 65px;
          height: 26px;
          line-height: 26px;
          text-align: center;
          color: #fff;
          background: linear-gradient(
            180deg,
            rgba(113, 198, 255, 1) 0%,
            rgba(55, 150, 255, 1) 100%
          );
          border-radius: 13px;
        }
      }
    }
  }
  .lookOverMore {
    height: 30px;
    margin-top: 10px;
    border-radius: 5px;
    text-align: center;
    line-height: 30px;
    font-size: 14px;
  }
  .noBack {
    background: transparent;
  }
}
</style>

<style lang="scss">
.vipShopList_father {
  height: 100vh;
  width: 100%;
  background: #f3f3f3;
  .van-search {
    background: transparent !important;
  }
}
</style>
