<!-- 洗车 -->
<template>
  <div class="content">
    <!-- <nav-bar title="洗车" v-if="isWeixin_status == false" /> -->
    <div class="storeContent">

    <!-- 多門店 -->
    <many-store v-if="storeStatus == 1"></many-store>
    <!-- 单个自营门店 -->
    <!-- 嫌麻烦取消掉（未完成） -->
    <!-- <single-self v-if="storeStatus == 2" :storeId="storeId"></single-self> -->
    <!-- 单个合作门店 -->
    <single-join v-if="storeStatus == 3 || storeStatus == 2" :storeId="storeId"></single-join>
    </div>
  </div>
</template>

<script>
import washCar from "@api/washCar"
import manyStore from "./manyStore";
import singleJoin from "./singleJoin";
// import singleSelf from "./singleSelf";
export default {
  data () {
    return {
      page: 1,
      storeStatus: "1", //  1为多个门店的情况， 2 为单个自营门店 3 单个合作门店
      storeData: [],
      storeId: "", //单个店铺的id
    };
  },

  components: {
    // Button,
    // Tabbar, TabbarItem,
    manyStore,
    singleJoin,
    // singleSelf
  },
  methods:{
    getList(){
      washCar.getStoreList().then(res => {
        console.log("列表", res);
        if(res.code == 200){
          let num = res.data.result.length;
          this.storeData = res.data.result;
          if(num == 1){
            // type 0：自营1：合作
            // this.storeStatus = this.storeData[0].type == 0? 2 : 3; //取消区别
            this.storeStatus = 2;
            this.storeId = this.storeData[0].id;
          }
        }

      });
    }
    // async getList(page) {
    //   try {
    //     const res = await washCar.getStoreList(page);
    //     if (res.code == 200) {
    //     console.log("列表", res);
         
    //     }
    //   } catch (error) {
    //     this.$toast.clear();
    //     console.log(error);
    //   }
    // }
  },
  mounted(){
    console.log("sadsad", this.$route.query);
    if(this.$route.query.status == 1){
      this.getList();
    }else{
      this.$router.push("/");
    }
  }
}
</script>
<style lang= 'scss' scoped>
.content{
  width: 100%;
  height: 100vh;
  img{
    width: 100%;
  }
  /* background: url("../../../../public/static/images/vip/bgWash.jpg") 100% no-repeat */
}
</style>